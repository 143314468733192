import $ from 'jquery';
window.jQuery = window.$ = $;

import Commons from '../commons/commons.js';

import './jquery.meanmenu.min.js';
import Tab from 'bootstrap/js/src/tab.js';
import Collapse from 'bootstrap/js/src/collapse.js';
import ScrollSpy from 'bootstrap/js/src/scrollspy.js';

import hljs from 'highlight.js/lib/core';

import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import perl from 'highlight.js/lib/languages/perl';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import json from 'highlight.js/lib/languages/json';
import plaintext from 'highlight.js/lib/languages/plaintext';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('php', php);
hljs.registerLanguage('perl', perl);
hljs.registerLanguage('python', python);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('json', json);
hljs.registerLanguage('plaintext', plaintext);

!(function($) {
    'use strict';

	hljs.highlightAll();

    $(".mean-menu").meanmenu({
        meanScreenWidth: "1199",
    });

	let header = $('.sticky-header');
	if (header.hasClass('hard-sticky') == false) {
	    $(window).on("scroll", function() {
	        var header = $(".sticky-header");
	        if ($(window).scrollTop() >= 200) {
	            header.addClass("is-sticky");
	        }
	        else {
	            header.removeClass("is-sticky");
	        }
	    });
	}

    $(window).on("scroll", function() {
        var goTop = $(".go-top");
        if ($(window).scrollTop() >= 200) {
            goTop.addClass("active");
        }
        else {
            goTop.removeClass("active")
        }
    });

    $(".go-top").on("click", function(e) {
        $("html, body").animate({
            scrollTop: 0,
        }, 0 );
    });

    $('.share-explain').click(function() {
        let iframe = $(this).siblings('iframe');
        iframe.attr('src', iframe.attr('data-src')).css('display', 'inline');
        $(this).remove();
    });

    Commons.initCommons($('body'));

    $('#newsletterForm').submit(function(e) {
        e.preventDefault();
        let form = $(this);
        form.find('button').prop('disabled', true);

        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function(data) {
                form.replaceWith('<div class="alert alert-success text-center">Email address has been saved!</div>');
            },
            error: function() {
                form.find('button').prop('disabled', false);
            }
        });
    });

})(jQuery);
